"use client";

import { Phone } from "lucide-react";
import { openScheduleAVisitModal } from "./schedule-a-visit";

const RequestACallButton = () => {
  const onClick = () => {
    openScheduleAVisitModal({ source: "request_a_call_footer" });
  };

  return (
    <button
      className="w-fit text-sm xl:text-base text-center text-primary-50 font-semibold bg-primary-950 hover:bg-primary-900 rounded-xl flex justify-center items-center px-12 py-4 gap-2"
      style={{
        boxShadow:
          "0px 12px 24px -6px rgba(45, 32, 17, 0.30), 0px 4px 8px 0px rgba(45, 32, 17, 0.20), 0px 1.5px 3px 0px rgba(45, 32, 17, 0.20), 0px 1px 1px 0px rgba(45, 32, 17, 0.12), 0px -3px 0px 0px rgba(0, 0, 0, 0.75) inset",
      }}
      onClick={onClick}
    >
      <Phone className="flex-shrink-0" />
      <span className="flex-shrink-0">Request a call</span>
    </button>
  );
};

export default RequestACallButton;
